<template>
  <div>
    <div class="loader">
      <div class="loader-inner">
        <div class="loader-line-wrap">
          <div class="loader-line"></div>
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line"></div>
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line"></div>
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line"></div>
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line"></div>
        </div>
      </div>
    </div>
    <p class="loader-text">{{ text }}</p>
  </div>
</template>

<script>
  export default {
    name  : 'RainbowLoader',
    props : {
      text : String,
    },
  }
</script>

<style lang="scss" scoped>
  @import '../../assets/scss/partials/rainbow-loader';
</style>